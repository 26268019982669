import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import { AuthState } from '@phillipsauctionhouse/auth-js/types';
import PhillipsTranslations from '../PhillipsTranslations/PhillipsTranslations';
import { user as userPropTypes } from '../PropTypes/proptypes';
import { headerAuctionProps, privateSalesMenuProps } from './proptypes';
import { useAuth } from '../Auth';
import sendAnalytics from '../../utils/sendAnalytics';

const Header = ({
  activeNode,
  amBidsUrl,
  getI18nLabel,
  getI18nLink,
  handleLanguageChange,
  handleLogin,
  handleOffCanvas,
  openModal,
  user,
  language,
  headerAuctions,
  notificationEnabled,
  privateSalesMenu,
  hasSticky,
}) => {
  
  const sendAnalyticsCall = (eventCategory, eventAction, eventLabel = 'www.phillips.com') => {
    sendAnalytics({
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel
    });
  }

  const { authState } = useAuth();
  return (
    <header
      className={classNames('phillips__nav__header', {
      'phillips__nav__header--notification': notificationEnabled,
      'phillips__nav__header__has-sticky': hasSticky
    })}
    >
      <nav className="container phillips__nav__header-wrapper">
        <ul className="phillips__nav__header__menu phillips__nav__header__menu--logo">
          <li className="phillips__nav__header__item phillips__nav__header__item--off-canvas phillips__nav__header__item--hidden-md">
            <button
              onClick={handleOffCanvas}
              value="side-menu"
              className="phillips__nav__header__item__button phillips__nav__header__item__button--off-canvas"
              title="Open Side Menu"
              type="button"
            >
              <img src="/images/menu.svg" width={24} height={24} alt="Hamburger Menu" />
            </button>
          </li>
          <li className="phillips__nav__header__item phillips__nav__header__item--logo">
            <a href={getI18nLink('/')} className="phillips__nav__logo" title="Homepage">
              <img src="/images/LogoBlack.svg" width={228} height={29} alt="Phillips Auctioneers Logo" />
            </a>
          </li>
          <li className="phillips__nav__header__item phillips__nav__header__item--search phillips__nav__header__item--hidden-md">
            <button
              onClick={handleOffCanvas}
              value="search"
              className="phillips__nav__header__item__button phillips__nav__header__item__button--off-canvas"
              title="Open Search on Phillips"
              type="button"
            >
              <img src="/images/search.svg" width={24} height={24} alt="Search icon" />
            </button>
          </li>
        </ul>
        {language === 'en' ? (
          <style
              dangerouslySetInnerHTML={{
              __html: `
                .phillips__nav__header__item__button {
                  letter-spacing: -0.6px;
                  padding: 0 3px;
                }
              `
            }}
          />
        ) : null}
        <div className="phillips__nav__header__menu phillips__nav__header__menu--primaryHeader">
          <ul className="phillips__nav__header__menu phillips__nav__header__menu--primary">
            <li className="phillips__nav__header__item">
              <button
                className={classNames('phillips__nav__header__item__button', {
                'phillips__nav__header__item__button--selected': activeNode === 'Auctions'
              })}
                type="button"
              >
                {getI18nLabel({ label: 'auctions' })}
              </button>
              <ul className="phillips__nav__header__item__submenu">
                {headerAuctions?.map((auction, index) => {
                while(index <= 14){
                  return (
                    <li className="phillips__nav__header__item" key={auction.saleNumber}>
                      <a
                        className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                        href={getI18nLink(auction.url)}
                        title={getI18nLabel({ label: auction.title, defaultValue: auction.title })}
                      >
                        {getI18nLabel({ label: auction.title, defaultValue: auction.title })}
                        {auction.includeLocation && auction.location
                        ? `, ${getI18nLabel({ label: camelCase(auction.location) })}`
                        : null}
                      </a>
                    </li>
                  );
                }
              })}
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href={getI18nLink('/calendar')}
                    title={getI18nLabel({ label: 'viewAllAuctions' , defaultValue: 'View All Auctions' })}
                  >
                    {getI18nLabel({ label: 'viewAllAuctions', defaultValue: 'View All Auctions' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <hr />
                </li>
                {/* <li className="phillips__nav__header__item">
                <a
                  className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                  href={getI18nLink("/digitalsaleroom")}
                  title={getI18nLabel({ label: 'digitalSaleroom' })}
                >
                  {getI18nLabel({ label: 'digitalSaleroom' })}
                </a>
              </li> */}
                {/* <li className="phillips__nav__header__item">
                <a
                  className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                  href={getI18nLink('/livestream')}
                  title="Phillips Live Stream"
                >
                  Phillips Live Stream
                </a>
              </li> */}
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href={getI18nLink('/auctions/past')}
                    title={getI18nLabel({ label: 'auctionResults' })}
                  >
                    {getI18nLabel({ label: 'auctionResults' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href={getI18nLink('/artists')}
                    title={getI18nLabel({ label: 'artistsAndMakers' })}
                  >
                    {getI18nLabel({ label: 'artistsAndMakers' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <hr />
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href={getI18nLink('/buysell/buy?auctions')}
                    title={getI18nLabel({ label: 'howToBuy' })}
                  >
                    {getI18nLabel({ label: 'howToBuy' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href={getI18nLink('/article/58561057/auction-bidding-anywhere')}
                    title={getI18nLabel({ label: 'remoteBidding' })}
                  >
                    {getI18nLabel({ label: 'remoteBidding' })}
                  </a>
                </li>
              </ul>
            </li>
            <li className="phillips__nav__header__item">
              <button
                className={classNames('phillips__nav__header__item__button', {
                'phillips__nav__header__item__button--selected': activeNode === 'Calendar'
            })}
                type="button"
              >
                <a href={getI18nLink('/calendar')} title={getI18nLabel({ label: 'auctionCalendar' })}>
                  {getI18nLabel({ label: 'calendar' })}
                </a>
              </button>
            </li>
            <li className="phillips__nav__header__item">
              <button
                className={classNames('phillips__nav__header__item__button', {
                'phillips__nav__header__item__button--selected': activeNode === 'Departments'
            })}
                type="button"
              >
                {getI18nLabel({ label: 'departments' })}
              </button>
              <ul className="phillips__nav__header__item__submenu">
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href="/modern-and-contemporary-art"
                    title={getI18nLabel({ label: 'ModernAndContemporaryArt' })}
                  >
                    {getI18nLabel({ label: 'ModernAndContemporaryArt' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href="/design"
                    title={getI18nLabel({ label: 'design' })}
                  >
                    {getI18nLabel({ label: 'design' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href="/editions"
                    title={getI18nLabel({ label: 'editions' })}
                  >
                    {getI18nLabel({ label: 'editions' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href="/jewels"
                    title={getI18nLabel({ label: 'jewels' })}
                  >
                    {getI18nLabel({ label: 'jewels' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href="/photographs"
                    title={getI18nLabel({ label: 'photographs' })}
                  >
                    {getI18nLabel({ label: 'photographs' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href="/watches"
                    title={getI18nLabel({ label: 'watches' })}
                  >
                    {getI18nLabel({ label: 'watches' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <hr />
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href="/private-sales-department"
                    title={getI18nLabel({ label: 'privateSales' })}
                  >
                    {getI18nLabel({ label: 'privateSales' })}
                  </a>
                </li>
              </ul>
            </li>
            {privateSalesMenu && privateSalesMenu?.length > 0? (
              <li className="phillips__nav__header__item">
                <button
                  className={classNames('phillips__nav__header__item__button', {
                  'phillips__nav__header__item__button--selected': activeNode === 'Exhibitions'
                })}
                  type="button"
                >
                  {getI18nLabel({ label: 'exhibitions' })}
                </button>
                <ul className="phillips__nav__header__item__submenu">
                  {privateSalesMenu.map(auction => (
                    <li className="phillips__nav__header__item" key={auction.saleNumber}>
                      <a
                        className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                        href={getI18nLink(auction.url)}
                        title={getI18nLabel({ label: auction.title, defaultValue: auction.title })}
                      >
                        {getI18nLabel({ label: auction.title, defaultValue: auction.title })}
                      </a>
                    </li>
                ))}
                </ul>
              </li>
        ): (
          <li className="phillips__nav__header__item">
            <button
              className={classNames('phillips__nav__header__item__button', {
                'phillips__nav__header__item__button--selected': activeNode === 'Exhibitions'
              })}
              type="button"
            >
              <a
                href="/exhibitions"
                title={getI18nLabel({ label: 'exhibitions' })}
              >
                {getI18nLabel({ label: 'exhibitions' })}
              </a>
            </button>
            
          </li>
        )}
            <li className="phillips__nav__header__item">
              <button
                className={classNames('phillips__nav__header__item__button', {
                'phillips__nav__header__item__button--selected': activeNode === 'Perpetual'
              })}
                type="button"
              >
                <a href={getI18nLink('/store/perpetual')} title={getI18nLabel({ label: 'perpetual' })}>
                  {getI18nLabel({ label: 'perpetual' })}
                </a>
              </button>
            </li>
            <li className="phillips__nav__header__item">
              <button
                className='phillips__nav__header__item__button'
                type="button"
              >
                <a href="http://dropshop.phillips.com" title='Dropshop' onClick={() => {sendAnalyticsCall('Dropshop click', 'Clicked Header Nav Link')}}>
                  Dropshop
                </a>
              </button>
            </li>
            <li className="phillips__nav__header__item">
              <button
                className={classNames('phillips__nav__header__item__button', {
                'phillips__nav__header__item__button--selected': activeNode === 'BuySell'
              })}
                type="button"
              >
                {getI18nLabel({ label: 'buyAndSell' })}
              </button>
              <ul className="phillips__nav__header__item__submenu">
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href={getI18nLink('/buysell/buy')}
                    title={getI18nLabel({ label: 'howToBuy' })}
                  >
                    {getI18nLabel({ label: 'howToBuy' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href={getI18nLink('/buysell/sell')}
                    title={getI18nLabel({ label: 'howToSell' })}
                  >
                    {getI18nLabel({ label: 'howToSell' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href={getI18nLink('/article/58561057/auction-bidding-anywhere')}
                    title={getI18nLabel({ label: 'remoteBidding' })}
                  >
                    {getI18nLabel({ label: 'remoteBidding' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href={getI18nLink('/buysell/private')}
                    title={getI18nLabel({ label: 'privateServices' })}
                  >
                    {getI18nLabel({ label: 'privateServices' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href={getI18nLink('/trusts-estates-valuations')}
                    title={getI18nLabel({ label: 'trustsEstatesAndValuations' })}
                  >
                    {getI18nLabel({ label: 'trustsEstatesAndValuations' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href={getI18nLink('/fiduciaryservices')}
                    title={getI18nLabel({ label: 'fiduciaryServices' })}
                  >
                    {getI18nLabel({ label: 'fiduciaryServices' })}
                  </a>
                </li>
              </ul>
            </li>
            <li className="phillips__nav__header__item">
              <button
                className={classNames('phillips__nav__header__item__button', {
                'phillips__nav__header__item__button--selected': activeNode === 'Editorial'
              })}
                type="button"
              >
                <a href={getI18nLink('/editorial')} title={getI18nLabel({ label: 'editorialTitle' })}>
                  {getI18nLabel({ label: 'editorial' })}
                </a>
              </button>
            </li>
            <li className="phillips__nav__header__item">
              <button
                className={classNames('phillips__nav__header__item__button', {
                'phillips__nav__header__item__button--selected': activeNode === 'About'
              })}
                type="button"
              >
                {getI18nLabel({ label: 'aboutUs' })}
              </button>
              <ul className="phillips__nav__header__item__submenu">
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href={getI18nLink('/about')}
                    title={getI18nLabel({ label: 'ourHistory' })}
                  >
                    {getI18nLabel({ label: 'ourHistory' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href="/team"
                    title={getI18nLabel({ label: 'ourTeam' })}
                  >
                    {getI18nLabel({ label: 'ourTeam' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href={getI18nLink('/location')}
                    title={getI18nLabel({ label: 'locations' })}
                  >
                    {getI18nLabel({ label: 'locations' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href="/press"
                    title={getI18nLabel({ label: 'press' })}
                  >
                    {getI18nLabel({ label: 'press' })}
                  </a>
                </li>
                <li className="phillips__nav__header__item">
                  <a
                    className="phillips__nav__header__item__link phillips__nav__header__item__link--submenu "
                    href="/careers"
                    title={getI18nLabel({ label: 'careers' })}
                  >
                    {getI18nLabel({ label: 'careers' })}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        {authState === AuthState.LoggedIn ? (
          <ul className="phillips__nav__header__menu phillips__nav__header__menu--user">
            <li className="phillips__nav__header__item">
              <a href={getI18nLink('/account')} title='My Account'>
                <img width="24px" height="24px" alt="My Account" src="/images/layout/ic_account_circle_48px_hover.svg" />
              </a>              
              <button
                className='phillips__nav__header__item__button'
                type="button"
              >
                <a href={getI18nLink('/account')} title='My Account'>
                  {getI18nLabel({ label: 'myAccount' })}
                </a>
              </button>
            </li>
          </ul>
      ) : (
        <ul className="phillips__nav__header__menu phillips__nav__header__menu--secondary">
          <li className="phillips__nav__header__item phillips__nav__header__item--no-children">
            <button
              className="phillips__nav__header__item__button phillips__nav__header__item__button--login"
              onClick={handleLogin}
              title={getI18nLabel({ label: 'logIn' })}
              type="button"
              value="login"
            >
              {getI18nLabel({ label: 'logIn' })}
            </button>
          </li>
        </ul>
      )}
        <ul className="phillips__nav__header__menu phillips__nav__header__menu--secondary">
          <li className="phillips__nav__header__item toggle">
            <button className="phillips__nav__header__item__button" type="button">
              {language === 'en' ? 'English' : '中文'}
            </button>
            <ul className="phillips__nav__header__item__submenu langSubmenu">
              {language === 'en' ? (
                <li className="phillips__nav__header__item">
                  <button
                    className="phillips__nav__header__item__button phillips__nav__header__item__button--login"
                    onClick={() => handleLanguageChange("zh")}
                    title="Chinese"
                    type="button"
                    value="zh"
                  >
                    中文
                  </button>
                </li>
            ) : (
              <li className="phillips__nav__header__item">
                <button
                  className="phillips__nav__header__item__button phillips__nav__header__item__button--login"
                  onClick={() => handleLanguageChange("en")}
                  title="English"
                  type="button"
                  value="en"
                >
                  English
                </button>
              </li>
            )}
            </ul>
          </li>
          <li className="phillips__nav__header__item phillips__nav__header__item--search">
            <button
              onClick={handleOffCanvas}
              value="search"
              className="phillips__nav__header__item__button phillips__nav__header__item__button--off-canvas"
              title="Open Search on Phillips"
              type="button"
            >
              <img src="/images/search.svg" width={24} height={24} alt="Search icon" />
            </button>
          </li>
        </ul>
      </nav>
    </header>
  )
};

Header.defaultProps = {
  activeNode: null,
  hasSticky: false
};

Header.propTypes = {
  activeNode: PropTypes.string,
  getI18nLabel: PropTypes.func.isRequired,
  getI18nLink: PropTypes.func.isRequired,
  handleLanguageChange: PropTypes.func.isRequired,
  handleOffCanvas: PropTypes.func.isRequired,
  notificationEnabled: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  privateSalesMenu: privateSalesMenuProps.isRequired,
  user: PropTypes.shape(userPropTypes).isRequired,
  headerAuctions: headerAuctionProps.isRequired,
  hasSticky: PropTypes.bool
};

export default PhillipsTranslations(Header);
